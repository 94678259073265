import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import SecondaryMenuSlot from '@/core/components/slots/SecondaryMenuSlot.vue';
import SecondaryMenuItem from '@/core/components/ui/SecondaryMenuItem.vue';
import { PROJECT_DASHBOARD_STORE } from '@/store/modules/project-dashboard';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    name: 'TradePassportHeader',
    components: {
        SecondaryMenuItem,
        SecondaryMenuSlot
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user']),
        ...mapGetters(USER_WORKSTATION, ['activeUserWorkstation']),
        ...mapGetters(PROJECT_DASHBOARD_STORE, ['getCurrentDashboardMenuId', 'getProjectDashboardMenu']),
        routeTitle() {
            return (this.$route?.meta?.title || '');
        },
    },
    created() {
        this.setPage(1);
    },
    methods: {
        ...mapActions(PROJECT_DASHBOARD_STORE, ['setCurrentDashboardMenuId']),
        setPage(id) {
            this.setCurrentDashboardMenuId(id);
            if (this.getCurrentDashboardMenuId) {
                const foundMenu = this.getProjectDashboardMenu.find((menu) => menu.id === this.getCurrentDashboardMenuId);
                if (foundMenu) {
                    this.$router.push({ name: foundMenu.route });
                }
            }
        },
    },
});
